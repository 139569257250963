import * as Cookies from 'js-cookie'
import router from '@/router'
import sidebarJson from '@/components/globals/sidebar/sidebar.json'

export const setCookie = (cookie) => {
  Cookies.set('superindo-token', cookie, { path: '/' })
  return {
    token: Cookies.get('superindo-token')
  }
}

export const setCookieProfile = (cookie) => {
  Cookies.set('superindo-profile', cookie, { path: '/' })
  return {
    profile: Cookies.get('superindo-profile')
  }
}

export const getUserToken = () => {
  return Cookies.get('superindo-token')
}

export const getUserProfile = () => {
  return JSON.parse(Cookies.get('superindo-profile'))
}

export const isLogin = () => {
  const token = Cookies.get('superindo-token')
  const profile = Cookies.get('superindo-profile')
  if (token && profile) {
    return true
  } 
  return false
}

export const logoutUser = () => {
  Cookies.remove('superindo-token', { path: '/' })
  Cookies.remove('superindo-profile', { path: '/' })
  localStorage.removeItem('role_managements')
  router.go(0)
}

export const getDataRole = () => {
  return JSON.parse(localStorage.getItem('role_managements'))
}

export const getDefaultRedirect = () => {
  if(isLogin()) {
    if(getUserProfile()?.role != 'Super Admin') {
      const dataRole = getDataRole()?.filter(item => item.view == true)
      const newSidebar = sidebarJson.map(item => item.child.filter(i => dataRole.map(role => role.name).includes(i.name))).flat()
      return newSidebar[0]?.to
    } else {
      return '/admin/campaign/campaigns'
    }
  }
}
