const BASE_URL_ACCOUNT = `${process.env.VUE_APP_API_URL_ACCOUNT}/dashboard`
const BASE_URL_ACCOUNT_MOBILE = `${process.env.VUE_APP_API_URL_ACCOUNT}/v1`
const BASE_URL_LOYALITY = `${process.env.VUE_APP_API_URL_LOYALITY}/dashboard`
const BASE_URL_CORE = `${process.env.VUE_APP_API_URL_CORE}/main/core`
// const BASE_URL_CORE2 = `${process.env.VUE_APP_API_URL_CORE2}/main/core`
// Auth
export const API_AUTH = {
  LOGIN: `${BASE_URL_ACCOUNT}/auth/sign-in`,
  LOGOUT: `${BASE_URL_ACCOUNT}/auth/sign-out`,
  VERIFICATION_LOGIN: `${BASE_URL_ACCOUNT}/auth/sign-in-verification`,
  FORGOT_PASSWORD: `${BASE_URL_ACCOUNT}/auth/forgot-password`,
  CHANGE_PASSWORD: `${BASE_URL_ACCOUNT}/user/change-password`,
  IDLE_SESSION: `${BASE_URL_ACCOUNT}/auth/idle`
}
export const API_CAMPAIGN = {
  LIST_CAMPAIGN: `${BASE_URL_LOYALITY}/campaign/list`,
  CREATE_CAMPAIGN: `${BASE_URL_LOYALITY}/campaign/add`,
  DETAIL_CAMPAIGN: id => `${BASE_URL_LOYALITY}/campaign/detail/${id}`,
  UPDATE_CAMPAIGN: id => `${BASE_URL_LOYALITY}/campaign/update/${id}`,
  DELETE_CAMPAIGN: id => `${BASE_URL_LOYALITY}/campaign/delete/${id}`,
  GET_PROMO_CODE: `${BASE_URL_CORE}/get-promo-info`,
  LIST_OFFER: `${BASE_URL_LOYALITY}/offer`,
  LIST_CONSUMER_OFFER: `${BASE_URL_LOYALITY}/offer/consumer-offer`,
  LIST_ALL_OFFER: `${BASE_URL_LOYALITY}/offer?is_all=true`,
  CREATE_OFFER: `${BASE_URL_LOYALITY}/offer`,
  DETAIL_OFFER: id => `${BASE_URL_LOYALITY}/offer/detail/${id}`,
  UPDATE_OFFER: id => `${BASE_URL_LOYALITY}/offer/${id}`,
  DELETE_OFFER: id => `${BASE_URL_LOYALITY}/offer/${id}`,
  LIST_BANNER: `${BASE_URL_LOYALITY}/banner`,
  DETAIL_BANNER: id => `${BASE_URL_LOYALITY}/banner/detail/${id}`,
  DELETE_BANNER: id => `${BASE_URL_LOYALITY}/banner/${id}`,
  CREATE_BANNER: `${BASE_URL_LOYALITY}/banner`,
  UPDATE_BANNER: id => `${BASE_URL_LOYALITY}/banner/${id}`,
  LIST_PUSH_MESSAGE: `${BASE_URL_LOYALITY}/push-message`,
  DETAIL_PUSH_MESSAGE: id => `${BASE_URL_LOYALITY}/push-message/detail/${id}`,
  DELETE_PUSH_MESSAGE: id => `${BASE_URL_LOYALITY}/push-message/${id}`,
  CREATE_PUSH_MESSAGE: `${BASE_URL_LOYALITY}/push-message`,
  UPDATE_PUSH_MESSAGE: id => `${BASE_URL_LOYALITY}/push-message/${id}`,
  LIST_ALL_TAG: `${BASE_URL_LOYALITY}/tag?is_all=true`,
  CALCULATE_TAG: `${BASE_URL_LOYALITY}/tag/calculate`,
  NOTIFICATION_OFFER: `${BASE_URL_ACCOUNT}/user-offer-notification`,
  LIST_ALL_NOTIF_OFFER: `${BASE_URL_ACCOUNT}/user-offer-notification?limit=999999999999999&page=1`,
  DELETE_NOTIFICATION_OFFER: id => `${BASE_URL_ACCOUNT}/user-offer-notification/${id}`
}

export const API_DIGITAL_STAMP = {
  GET_PROMO_CODE: `${BASE_URL_CORE}/get-stamp-info`,
  GET_PROMO_CODE_REWARD: `${BASE_URL_CORE}/get-reward-info`,
  STAMP: `${BASE_URL_LOYALITY}/stamp`,
  STAMP_DETAIL: id => `${BASE_URL_LOYALITY}/stamp/detail/${id}`,
  STAMP_UPDATE_DELETE: id => `${BASE_URL_LOYALITY}/stamp/${id}`,
  BULK_UPLOAD_PRODUCT: `${BASE_URL_LOYALITY}/stamp/bulk-upload-excluded-product`,
  REWARD: `${BASE_URL_LOYALITY}/reward`,
  REWARD_DETAIL: id => `${BASE_URL_LOYALITY}/reward/detail/${id}`,
  REWARD_UPDATE_DELETE: id => `${BASE_URL_LOYALITY}/reward/${id}`,
  HOWTO_REDEEM: `${BASE_URL_LOYALITY}/how-to-redeem`,
  CONSUMER_STAMP_HISTORY: `${BASE_URL_LOYALITY}/stamp/consumer-stamp-history`,
  CONSUMER_COUPON_HISTORY: `${BASE_URL_LOYALITY}/reward/consumer-coupon-history`,
  CONSUMER_COUPON_HISTORY_DETAIL: id => `${BASE_URL_LOYALITY}/reward/consumer-coupon-history/detail/${id}`,
  EXPORT_CONSUMER_STAMP_HISTORY: `${BASE_URL_LOYALITY}/stamp/consumer-stamp-history/csv`,
  EXPORT_CONSUMER_COUPON_HISTORY: `${BASE_URL_LOYALITY}/reward/consumer-coupon-history/csv`,
  REPORT_COUPON: `${BASE_URL_LOYALITY}/report/coupon`,
  REPORT_COUPON_DETAIL: id => `${BASE_URL_LOYALITY}/report/coupon/detail/${id}`,
  EXPORT_REPORT_COUPON: `${BASE_URL_LOYALITY}/report/coupon/csv`,
  REPORT_PRODUCT: `${BASE_URL_LOYALITY}/report/product`,
  EXPORT_REPORT_PRODUCT: `${BASE_URL_LOYALITY}/report/product/csv`
}

export const API_POLLING_SURVEY = {
  FORM: `${BASE_URL_LOYALITY}/polling-and-survey/form`,
  FORM_DETAIL: id => `${BASE_URL_LOYALITY}/polling-and-survey/form/detail/${id}`,
  FORM_UPDATE_DELETE: id => `${BASE_URL_LOYALITY}/polling-and-survey/form/${id}`,
  FORM_DETAIL_RESPONSE: id => `${BASE_URL_LOYALITY}/polling-and-survey/form/response/${id}`,
  EXPORT_REPORT_FORM_RESPONSE: id => `${BASE_URL_LOYALITY}/polling-and-survey/form/response/csv/${id}`,
  RESPONSE: `${BASE_URL_LOYALITY}/polling-and-survey/response`,
  RESPONSE_DETAIL: id => `${BASE_URL_LOYALITY}/polling-and-survey/response/detail/${id}`,
  EXPORT_RESPONSE: `${BASE_URL_LOYALITY}/polling-and-survey/response/csv`
}

export const API_CONSUMER = {
  LIST_CONSUMER: `${BASE_URL_ACCOUNT}/consumer`,
  DETAIL_CONSUMER: id => `${BASE_URL_ACCOUNT}/consumer/detail/${id}`,
  UPDATE_CONSUMER: id => `${BASE_URL_ACCOUNT}/consumer/update/${id}`,
  EXISTING_PHONE_NUMBER: `${BASE_URL_ACCOUNT}/consumer/existing-phone-number`,
  LOCK_ACCOUNT_CONSUMER: id => `${BASE_URL_ACCOUNT}/consumer/lock-consumer/${id}`,
  UNLOCK_ACCOUNT_CONSUMER: id => `${BASE_URL_ACCOUNT}/consumer/unlock-consumer/${id}`,
  WITHDRAW_CONSUMER: id => `${BASE_URL_ACCOUNT}/consumer/withdraw-consumer/${id}`,
  UNWITHDRAW_CONSUMER: id => `${BASE_URL_ACCOUNT}/consumer/unwithdraw-consumer/${id}`,
  CONSUMER_ACTIVITY_LOG: id => `${BASE_URL_ACCOUNT}/consumer-log-history/${id}`,
  RESEND_EMAIL_CONSUMER: id => `${BASE_URL_ACCOUNT}/consumer/verification/${id}`,
  POS_SALES_HISTORY: `${BASE_URL_LOYALITY}/transaction/pos-sales-history`,
  UNLINK_CARD: `${BASE_URL_ACCOUNT}/consumer/unlink-card`,
  LINK_CARD: `${BASE_URL_ACCOUNT}/consumer/link-card`,
  LIST_CATEGORY_FAQ: `${BASE_URL_LOYALITY}/cms-faq-category/options`,
  LIST_CATEGORY_HC: `${BASE_URL_ACCOUNT}/consumer-help-center/category`,
  LIST_TYPE_HC: `${BASE_URL_ACCOUNT}/consumer-help-center/type`,
  LIST_FAQ: `${BASE_URL_LOYALITY}/cms-faq`,
  LIST_HELP_CENTER: `${BASE_URL_ACCOUNT}/consumer-help-center`,
  EXPORT_HELP_CENTER: `${BASE_URL_ACCOUNT}/consumer-help-center/export/csv`,
  DETAIL_HELP_CENTER:  id => `${BASE_URL_ACCOUNT}/consumer-help-center/${id}`,
  SOLVED_HELP_CENTER: id => `${BASE_URL_ACCOUNT}/consumer-help-center/${id}`,
  SOLVED_HELP_CENTER_BULK: `${BASE_URL_ACCOUNT}/consumer-help-center/bulk`,
  LIST_APPROVAL_MESSAGE: `${BASE_URL_ACCOUNT}/approval-user`,
  LIST_APPROVAL_MESSAGE_ALL: `${BASE_URL_ACCOUNT}/approval-user?limit=999999999999999&page=1`,
  DELETE_APPROVAL: id => `${BASE_URL_ACCOUNT}/approval-user/${id}`,
  CREATE_APPROVAL: `${BASE_URL_ACCOUNT}/approval-user`,
  CREATE_FAQ: `${BASE_URL_LOYALITY}/cms-faq`,
  DETAIL_FAQ: id => `${BASE_URL_LOYALITY}/cms-faq/${id}`,
  UPDATE_FAQ: id => `${BASE_URL_LOYALITY}/cms-faq/${id}`,
  DELETE_FAQ: id => `${BASE_URL_LOYALITY}/cms-faq/${id}`,
  LIST_GENDER: `${BASE_URL_LOYALITY}/gender`,
  LIST_REGION: `${BASE_URL_LOYALITY}/region`,
  LIST_CONSUMER_TAG: `${BASE_URL_LOYALITY}/tag/consumer-tag`,
  TEMPLATE_HELP_CENTER: `${BASE_URL_ACCOUNT}/template-help-center`,
  UPDATE_SOLVE_BY_EMAIL: id => `${BASE_URL_ACCOUNT}/consumer-help-center/share/${id}`,
  BULK_UPLOAD_CONSUMER_HC: `${BASE_URL_ACCOUNT}/consumer-help-center/bulk-upload-consumer-help-center`
}

export const API_MASTER_DATA = {
  LIST_CONSUMER_BY_DETAIL_CUSTOM_TAG: `${BASE_URL_ACCOUNT}/consumer/list-consumer`,
  CONSUMER_TAG: `${BASE_URL_LOYALITY}/tag`,
  BULK_UPLOAD_CONSUMER_TAG: `${BASE_URL_ACCOUNT}/consumer/bulk-upload-consumer-tag`,
  UPDATE_CUSTOM_CONSUMER_TAG: id => `${BASE_URL_LOYALITY}/tag/update/${id}`,
  DETAIL_CUSTOM_CONSUMER_TAG: id => `${BASE_URL_LOYALITY}/tag/detail/${id}`,
  DELETE_CUSTOM_CONSUMER_TAG: id => `${BASE_URL_LOYALITY}/tag/delete/${id}`,
  LIST_ALL_GROUP_STORE: `${BASE_URL_LOYALITY}/group-store?is_all=true`,
  LIST_GROUP_STORE: `${BASE_URL_LOYALITY}/group-store`,
  CREATE_GROUP_STORE: `${BASE_URL_LOYALITY}/group-store/create`,
  DETAIL_GROUP_STORE: id => `${BASE_URL_LOYALITY}/group-store/detail/${id}`,
  UPDATE_GROUP_STORE: id => `${BASE_URL_LOYALITY}/group-store/update/${id}`,
  DELETE_GROUP_STORE: id => `${BASE_URL_LOYALITY}/group-store/delete/${id}`,
  LIST_STORE_FROM_GROUP_STORE: data => `${BASE_URL_LOYALITY}/group-store/list-store?${data}`,
  LIST_ALL_STORE: `${BASE_URL_LOYALITY}/store?is_all=true`,
  SYNC_STORE: `${BASE_URL_CORE}/sync-store`,
  LIST_STORE: `${BASE_URL_LOYALITY}/store`,
  LIST_PRODUCT: `${BASE_URL_LOYALITY}/product`,
  LIST_PRODUCT_CATEGORY: `${BASE_URL_LOYALITY}/product-category?is_all=true`,
  PRIVACY_POLICY: `${BASE_URL_LOYALITY}/privacy-policy`,
  SETTING_APP: id => `${BASE_URL_ACCOUNT}/config/mobile/${id}`,
  SETTING_APP_ANDROID: `${BASE_URL_ACCOUNT}/config/mobile/android`,
  SETTING_APP_IOS: `${BASE_URL_ACCOUNT}/config/mobile/ios`,
  TERM_CONDITION: `${BASE_URL_LOYALITY}/terms-and-condition`
}

export const API_USER_MANAGEMENT = {
  LIST_ROLE: `${BASE_URL_ACCOUNT}/role/list`,
  CREATE_ROLE: `${BASE_URL_ACCOUNT}/role/add`,
  DETAIL_ROLE: id => `${BASE_URL_ACCOUNT}/role/detail/${id}`,
  DELETE_ROLE: id => `${BASE_URL_ACCOUNT}/role/delete/${id}`,
  UPDATE_ROLE: id => `${BASE_URL_ACCOUNT}/role/update/${id}`,
  LIST_USER: `${BASE_URL_ACCOUNT}/user/list`,
  LIST_USER_ALL: `${BASE_URL_ACCOUNT}/user/options`,
  CREATE_USER: `${BASE_URL_ACCOUNT}/user`,
  ALL_DETAIL_USER: `${BASE_URL_ACCOUNT}/user/detail`,
  DETAIL_USER: id => `${BASE_URL_ACCOUNT}/user/detail/${id}`,
  UPDATE_USER: id => `${BASE_URL_ACCOUNT}/user/update/${id}`,
  DELETE_USER: id => `${BASE_URL_ACCOUNT}/user/delete/${id}`,
  RESET_PASSWORD: `${BASE_URL_ACCOUNT}/user/reset-password`,
  RESET_PASSWORD_USER_BY_ID: id => `${BASE_URL_ACCOUNT}/user/reset-password/${id}`,
  DETAIL_PROFILE: `${BASE_URL_ACCOUNT}/user/profile`,
  USER_LOG_HISTORY: `${BASE_URL_ACCOUNT}/user-log-history`
}

// Employee
export const API_MOBILE = {
  RESET_PASSWORD: `${BASE_URL_ACCOUNT_MOBILE}/auth/change-password`,
  EMAIL_VERIFICATION: `${BASE_URL_ACCOUNT_MOBILE}/auth/verification`
}
export const API_USER = {
  UPDATE_USER_PROFILE:  `${BASE_URL_ACCOUNT}/user/profile`,
  DETAIL_USER: (id) => `${BASE_URL_LOYALITY}/user/${id}`
}
