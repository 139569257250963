/* eslint-disable no-undef */
import 'dayjs/locale/id'
import dayjs from 'dayjs'
const utc = require('dayjs/plugin/utc')
dayjs.locale('id')
dayjs.extend(utc)

// Date & Time
export const dateTimeStringFullTimeDash = (date) => {
  if (date) return dayjs(date).format('DD-MM-YYYY HH:mm:ss')
  return null
}
export const dateTimeStringFullTimeDash2 = (date) => {
  if (date) return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
  return null
}
export const dateTimeStringFullTime = (date) => {
  if (date) return dayjs(date).format('DD/MMM/YYYY HH:mm:ss')
  return null
}

export const datetimeString = (date) => {
  if (date) return dayjs(date).format('DD/MM/YYYY HH:mm')
  return null
}

export const dateNowParse = (date) => {
  if (date) {
    return dayjs(date).format('DD/MM/YYYY')
  } else {
    return dayjs().format('DD/MM/YYYY')
  }
}

export const dateStringDashFromDay = (date) => {
  if (date) return dayjs(date).format('DD-MM-YYYY')
  return null
}

export const dateString = (date) => {
  if (date) return dayjs(date).format('YYYY-MM-DD')
  return null
}

export const dateStringAdd = (type, count, unit) => {
  if (type == 'min') {
    if (type) return dayjs().add(count, unit).format('YYYY-MM-DD')
    return null
  }
}

export const customDateMonthString = (date, param = 'slash') => {
  if (param == 'slash') {
    if (date) return dayjs(date).format('DD/MMM/YYYY')
  } else if (param == 'fulltime') {
    if (date) return dayjs(date).format('DD/MMM/YYYY, HH:mm:ss')
  } else if (param == 'fulltime-space') {
    if (date) return dayjs(date).locale('en').format('DD MMMM YYYY, HH:mm:ss')
  } else {
    if (date) return dayjs(date).format('DD MMM YYYY')
  }
  return null
}
export const formatUTCToLocalTime2 = (date) => {
  // 02-01-2020 17:00:00 -> 03-01-2020 00:00:00
  if (date) return dayjs.utc(date).local().format('MM/DD/YYYY HH:mm:ss')
  return null
}
export const formatUTCToLocalTime = (date) => {
  // 02-01-2020 17:00:00 -> 03-01-2020 00:00:00
  if (date) return dayjs.utc(date).local().format('DD/MM/YYYY HH:mm:ss')
  return null
}
export const formatUTCToLocalTimeCustomMonthNoTime = (date) => {
  // 02-01-2020 17:00:00 -> 03-01-2020 00:00:00
  if (date) return dayjs.utc(date).local().format('DD/MMM/YYYY')
  return null
}
export const formatUTCToLocalTimeCustomNoTimeNoFormatMonth = (date) => {
  // 02-01-2020 17:00:00 -> 03-01-2020 00:00:00
  if (date) return dayjs.utc(date).local().format('MM-DD-YYYY')
  return null
}
export const formatUTCToLocalTimeCustomMonth = (date) => {
  // 02-01-2020 17:00:00 -> 03-01-2020 00:00:00
  if (date) return dayjs.utc(date).local().format('DD/MMM/YYYY HH:mm:ss')
  return null
}
export const formatDateTimeToUTC = (date) => {
  // 02-01-2020 00:00:00 -> 01-01-2020 17:00:00
  if (date) { return dayjs.utc(dayjs(date)).format('YYYY-MM-DD HH:mm:ss') }
  return null
}
export const formatDateTimeToUTCStatic1 = (date) => {
  // 02-01-2020 00:00:00 -> 01-01-2020 17:00:00
  if (date) { return dayjs.utc(dayjs(`${date} 00:00:00`)).format('YYYY-MM-DD HH:mm:ss') }
  return null
}
export const formatDateTimeToUTCStatic2 = (date) => {
  // 02-01-2020 00:00:00 -> 01-01-2020 17:00:00
  if (date) { return dayjs.utc(dayjs(`${date} 23:59:59`)).format('YYYY-MM-DD HH:mm:ss') }
  return null
}

export const currency = (value) => {
  if (value) return `Rp ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}` //NOSONAR
}

export const currencyWithoutRp = (value) => {
  if (value) return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}` //NOSONAR
}

export const expired = (date) => {
  if (date) {
    const dates = dayjs(date).format('YYYY-MM-DD')
    if (dayjs(dates).isBefore(dayjs(new Date()).format('YYYY-MM-DD'))) {
      return true
    } else {
      return false
    }
  }
}
