import {apiPostNonAuth, apiPostAuth, apiPutAuth, apiDeleteAuth} from '@/utils/api'
import { API_AUTH, API_USER, API_USER_MANAGEMENT } from '@/utils/api-url'
import { setCookie, setCookieProfile, logoutUser } from '@/utils/auth'
import {
  notificationSuccess,
  notificationDanger
} from '@/utils/notification'
import router from '@/router'
import { getDefaultRedirect } from '@/utils/auth'

const state = () => {
  return {
    role_managements: { data: [] },
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loading: false,
      isSendForgot: true
    }
  }
}

const mutations = {
  SET_ROLE_MANAGEMENT (state, payload) {
    state.role_managements = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  }
}

const actions = {
  async login(context, payload) {
    try {
      const { data } = await apiPostNonAuth(API_AUTH.LOGIN, payload)
      if (data?.data?.role?.name == "Super Admin" && data.data.token == '') {
        return data
      } else {
        if (data?.message == 'OK') {
          setCookie(data.data.token)
          const profile = {
            "name": data.data.username || 'Admin Default',
            "role": data.data?.role?.name || "Role Default"
          }
          setCookieProfile(profile)
          if (data.data?.role?.name != 'Super Admin') {
            const filterRole = data?.data?.role?.managements.filter(item => item.type == 'cms')
            if (filterRole?.length < 1) {
              router.push({name: 'RoleInactive'})
              localStorage.setItem('role_managements', JSON.stringify(filterRole))
              return
            }
            localStorage.setItem('role_managements', JSON.stringify(filterRole))
          } else {
            localStorage.setItem('role_managements', JSON.stringify([]))
          }
    
          notificationSuccess('Login Success')
          setTimeout(() => {
            if (router.currentRoute?.value?.query?.redirect && !!router.currentRoute?.value?.query?.redirect) {
              router.push(router.currentRoute.value.query.redirect)
            } else {
              if(data.data?.role?.name != 'Super Admin') {
                const redirectTo = getDefaultRedirect()
                router.push(redirectTo)
              } else {
                router.go(0)
              }
            }
          }, 1000)
        }
      }
    } catch (err) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(err)
    }
  },
  async verificationLogin(context, payload) {
    try {
      const response = await apiPostNonAuth(API_AUTH.VERIFICATION_LOGIN, payload)
      if (response?.data?.message == 'OK') {
        setCookie(response.data.data.token)
        const profile = {
          "name": response.data.data.username || 'Admin Default',
          "role": response.data.data?.role?.name || "Role Default"
        }
        setCookieProfile(profile)
        if (response.data.data?.role?.name != 'Super Admin') {
          localStorage.setItem('role_managements', JSON.stringify(response.data.data?.role?.managements.filter(item => item.type == 'cms')))
        } else {
          localStorage.setItem('role_managements', JSON.stringify([]))
        }
  
        notificationSuccess('Login Success')
        setTimeout(() => {
          if (router.currentRoute?.value?.query?.redirect && !!router.currentRoute?.value?.query?.redirect) {
            router.push(router.currentRoute.value.query.redirect)
          } else {
            if(response.data.data?.role?.name != 'Super Admin') {
              const redirectTo = getDefaultRedirect()
              router.push(redirectTo)
            } else {
              router.go(0)
            }
          }
        }, 1000)
      } else {
        context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
          targetModals: false,
          title: '', 
          description: '',
          customTitleBtnOk: '',
          customClass: '',
          type: '',
          loading: false
        })
        notificationDanger('Something went wrong!')
      }

    } catch (err) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      return err
      // notificationDanger(err)
    }
  },
  async forgotPassword(context, payload) {
    try {
      const response = await apiPostNonAuth(API_AUTH.FORGOT_PASSWORD, payload)
      return response.data
    } catch (err) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false,
        isSendForgot: true
      })
      notificationDanger(err)
    }
  },
  async idleSession(context) {
    try {
      const payload = {
        type: 'cms'
      }
      const response = await apiPostAuth(API_AUTH.IDLE_SESSION, payload)
      if (response.data?.data?.is_idle === true) {
        logoutUser()
      }
    } catch (err) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(err)
    }
  },
  async updateProfile(context, payload) {
    try {
      const response = await apiPutAuth(API_USER.UPDATE_USER_PROFILE, payload)
      return response.data
    } catch (err) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(err)
    }
  },
  async changePassword(context, payload) {
    try {
      const response = await apiPutAuth(API_AUTH.CHANGE_PASSWORD, payload)
      return response.data
    } catch (err) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(err)
    }
  },
  async resetPasswordUserByID(context, payload) {
    try {
      const response = await apiPostAuth(API_USER_MANAGEMENT.RESET_PASSWORD_USER_BY_ID(payload))
      return response.data
    } catch (err) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(err)
    }
  },
  async resetPassword(context, payload) {
    try {
      const response = await apiPostNonAuth(API_USER_MANAGEMENT.RESET_PASSWORD, payload)
      return response.data
    } catch (err) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(err)
    }
  },
  async logout() {
    try {
      await apiDeleteAuth(API_AUTH.LOGOUT)
      notificationSuccess('Logout Success')
      setTimeout(() => {
        logoutUser()
      }, 1000)
    } catch (err) {
      notificationDanger(err)
    }
  }
}

const getters = {
  list_role_management (state) {
    return state.role_managements
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
