import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth } from '@/utils/api'
import { API_CONSUMER, API_USER_MANAGEMENT } from '@/utils/api-url'
import router from '@/router'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    detail_user: { data: []},
    faqs: { data: [] },
    detail_faq: { data: {}},
    listCategory: { data: [] },
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_DETAIL_USER (state, payload) {
    state.detail_user = payload
  },
  SET_LIST_CATEGORY (state, payload) {
    state.listCategory = payload
  },
  SET_FAQS (state, payload) {
    state.faqs = payload
  },
  SET_DETAIL_FAQ (state, payload) {
    state.detail_faq = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListAllDetailUser (context, payload) {
    try {
      const response = await apiPostAuth(API_USER_MANAGEMENT.ALL_DETAIL_USER, payload)
      context.commit('SET_DETAIL_USER', response.data.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListCategory (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.LIST_CATEGORY_FAQ, payload)
      context.commit('SET_LIST_CATEGORY', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListFaq (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.LIST_FAQ, payload)
      context.commit('SET_FAQS', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({ name: 'Faq', query: payload })
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailFaq (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.DETAIL_FAQ(payload))
      context.commit('SET_DETAIL_FAQ', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createFaq (context, payload) {
    try {
      const response = await apiPostAuth(API_CONSUMER.CREATE_FAQ, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async updateFaq (context, payload) {
    try {
      const response = await apiPutAuth(API_CONSUMER.UPDATE_FAQ(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async deleteFaq (context, payload) {
    try {
      const response = await apiDeleteAuth(API_CONSUMER.DELETE_FAQ(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  detail_user (state) {
    return state.detail_user
  },
  list_category (state) {
    return state.listCategory
  },
  list_faq (state) {
    return state.faqs
  },
  detail_faq (state) {
    return state.detail_faq
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
