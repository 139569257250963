import { createRouter, createWebHistory } from "vue-router"
import { isLogin } from '@/utils/auth'
import EmptyPageLayout from '@/components/layouts/EmptyPage'
import Login from '@/components/pages/Login'
import { getDefaultRedirect } from "@/utils/auth"

const routes = [
  {
    path: '/',
    component: EmptyPageLayout,
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: '/forgot-password',
        name: 'Forgot',
        component: () => import(/* webpackChunkName: "forgot" */ '@/components/pages/Forgot'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: '/reset-password',
        name: 'Reset',
        component: () => import(/* webpackChunkName: "forgot" */ '@/components/pages/Forgot'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: 'mobile/reset-password',
        name: 'MobileResetPassword',
        component: () => import(/* webpackChunkName: "forgot" */ '@/components/pages/mobile/ResetPassword'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: 'mobile/email-verification',
        name: 'EmailVerification',
        component: () => import(/* webpackChunkName: "forgot" */ '@/components/pages/mobile/EmailVerification'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: 'mobile/failed-page',
        name: 'FailedPage',
        component: () => import(/* webpackChunkName: "forgot" */ '@/components/pages/mobile/FailedPage'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: 'mobile/success-page',
        name: 'SuccessPage',
        component: () => import(/* webpackChunkName: "forgot" */ '@/components/pages/mobile/SuccessPage'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: '/terms',
        name: 'Terms',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/Term'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: '/privacy',
        name: 'Privacy',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/Privacy'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      }
    ]
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin-layout" */ '@/components/layouts/Admin'),
    redirect: getDefaultRedirect(),
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/pages/admin/dashboard')
      },
      {
        path: 'campaign/offers',
        name: 'Offers',
        component: () => import(/* webpackChunkName: "offers" */ '@/components/pages/admin/campaign/offers')
      },
      {
        path: 'campaign/offers/create',
        name: 'OffersCreate',
        component: () => import(/* webpackChunkName: "offers-create" */ '@/components/pages/admin/campaign/offers/create')
      },
      {
        path: 'campaign/offers/:id/detail',
        name: 'OffersDetail',
        props: true,
        component: () => import(/* webpackChunkName: "offers-detail" */ '@/components/pages/admin/campaign/offers/detail')
      },
      {
        path: 'campaign/offers/:id/edit',
        name: 'OffersEdit',
        props: true,
        component: () => import(/* webpackChunkName: "offers-edit" */ '@/components/pages/admin/campaign/offers/edit')
      },
      {
        path: 'campaign/offers/:id/duplicate',
        name: 'OffersDuplicate',
        props: true,
        component: () => import(/* webpackChunkName: "offers-duplicate" */ '@/components/pages/admin/campaign/offers/duplicate')
      },
      {
        path: 'campaign/banners',
        name: 'Banners',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/campaign/banners')
      },
      {
        path: 'campaign/banners/create',
        name: 'BannersCreate',
        props: true,
        component: () => import(/* webpackChunkName: "banners-create" */ '@/components/pages/admin/campaign/banners/create')
      },
      {
        path: 'campaign/banners/:id/detail',
        name: 'BannersDetail',
        props: true,
        component: () => import(/* webpackChunkName: "banners-detail" */ '@/components/pages/admin/campaign/banners/detail')
      },
      {
        path: 'campaign/banners/:id/edit',
        name: 'BannersEdit',
        props: true,
        component: () => import(/* webpackChunkName: "banners-edit" */ '@/components/pages/admin/campaign/banners/edit')
      },
      {
        path: 'campaign/banners/:id/duplicate',
        name: 'BannersDuplicate',
        props: true,
        component: () => import(/* webpackChunkName: "banners-duplicate" */ '@/components/pages/admin/campaign/banners/duplicate')
      },
      {
        path: 'campaign/campaigns',
        name: 'Campaigns',
        component: () => import(/* webpackChunkName: "campaigns" */ '@/components/pages/admin/campaign/campaigns')
      },
      {
        path: 'campaign/campaigns/create',
        name: 'CampaignsCreate',
        component: () => import(/* webpackChunkName: "campaigns-create" */ '@/components/pages/admin/campaign/campaigns/create')
      },
      {
        path: 'campaign/campaigns/:id/detail',
        name: 'CampaignsDetail',
        props: true,
        component: () => import(/* webpackChunkName: "campaigns-detail" */ '@/components/pages/admin/campaign/campaigns/detail')
      },
      {
        path: 'campaign/campaigns/:id/edit',
        name: 'CampaignsEdit',
        props: true,
        component: () => import(/* webpackChunkName: "campaigns-edit" */ '@/components/pages/admin/campaign/campaigns/edit')
      },
      {
        path: 'campaign/push-messages',
        name: 'PushMessages',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/campaign/push-messages')
      },
      {
        path: 'campaign/push-messages/create',
        name: 'PushMessagesCreate',
        props: true,
        component: () => import(/* webpackChunkName: "push-messages-create" */ '@/components/pages/admin/campaign/push-messages/create')
      },
      {
        path: 'campaign/push-messages/:id/detail',
        name: 'PushMessagesDetail',
        props: true,
        component: () => import(/* webpackChunkName: "push-messages-detail" */ '@/components/pages/admin/campaign/push-messages/detail')
      },
      {
        path: 'campaign/push-messages/:id/edit',
        name: 'PushMessagesEdit',
        props: true,
        component: () => import(/* webpackChunkName: "push-messages-edit" */ '@/components/pages/admin/campaign/push-messages/edit')
      },
      {
        path: 'campaign/push-messages/:id/duplicate',
        name: 'PushMessagesDuplicate',
        props: true,
        component: () => import(/* webpackChunkName: "push-messages-duplicate" */ '@/components/pages/admin/campaign/push-messages/duplicate')
      },
      {
        path: 'campaign/approval-messages',
        name: 'ApprovalMessages',
        component: () => import(/* webpackChunkName: "approval-messages" */ '@/components/pages/admin/campaign/approval-messages')
      },
      {
        path: 'campaign/approval-messages/edit',
        name: 'ApprovalMessagesEdit',
        props: true,
        component: () => import(/* webpackChunkName: "approval-messages-edit" */ '@/components/pages/admin/campaign/approval-messages/edit')
      },
      {
        path: 'campaign/notification-offer',
        name: 'NotifOffer',
        component: () => import(/* webpackChunkName: "notification-offer" */ '@/components/pages/admin/campaign/notification-offer')
      },
      {
        path: 'campaign/notification-offer/edit',
        name: 'NotifOfferEdit',
        props: true,
        component: () => import(/* webpackChunkName: "notification-offer-edit" */ '@/components/pages/admin/campaign/notification-offer/edit')
      },
      {
        path: 'digital-stamp/stamp',
        name: 'Stamps',
        component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/admin/digital-stamp/stamp')
      },
      {
        path: 'digital-stamp/stamp/create',
        name: 'StampsCreate',
        component: () => import(/* webpackChunkName: "stamps-create" */ '@/components/pages/admin/digital-stamp/stamp/create')
      },
      {
        path: 'digital-stamp/stamp/:id/detail',
        name: 'StampsDetail',
        props: true,
        component: () => import(/* webpackChunkName: "stamps-detail" */ '@/components/pages/admin/digital-stamp/stamp/detail')
      },
      {
        path: 'digital-stamp/stamp/:id/edit',
        name: 'StampsEdit',
        props: true,
        component: () => import(/* webpackChunkName: "stamps-edit" */ '@/components/pages/admin/digital-stamp/stamp/edit')
      },
      {
        path: 'digital-stamp/reward',
        name: 'Rewards',
        component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/admin/digital-stamp/reward')
      },
      {
        path: 'digital-stamp/reward/create',
        name: 'RewardsCreate',
        component: () => import(/* webpackChunkName: "stamps-create" */ '@/components/pages/admin/digital-stamp/reward/create')
      },
      {
        path: 'digital-stamp/reward/:id/detail',
        name: 'RewardsDetail',
        props: true,
        component: () => import(/* webpackChunkName: "stamps-detail" */ '@/components/pages/admin/digital-stamp/reward/detail')
      },
      {
        path: 'digital-stamp/reward/:id/edit',
        name: 'RewardsEdit',
        props: true,
        component: () => import(/* webpackChunkName: "stamps-edit" */ '@/components/pages/admin/digital-stamp/reward/edit')
      },
      {
        path: 'digital-stamp/report',
        name: 'Reports',
        component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/admin/digital-stamp/report')
      },
      {
        path: 'digital-stamp/coupon/:id/detail',
        name: 'ReportsDetail',
        props: true,
        component: () => import(/* webpackChunkName: "stamps" */ '@/components/pages/admin/digital-stamp/report/coupon/detail')
      },
      {
        path: 'digital-stamp/how-to-redeem',
        name: 'HowtoRedeem',
        component: () => import(/* webpackChunkName: "store" */ '@/components/pages/admin/digital-stamp/how-to-redeem')
      },
      {
        path: 'digital-stamp/how-to-redeem/edit',
        name: 'HowtoRedeemEdit',
        component: () => import(/* webpackChunkName: "store" */ '@/components/pages/admin/digital-stamp/how-to-redeem/edit')
      },
      {
        path: 'polling-survey/form',
        name: 'Form',
        component: () => import(/* webpackChunkName: "form" */ '@/components/pages/admin/polling-survey/form')
      },
      {
        path: 'polling-survey/form/create',
        name: 'FormCreate',
        component: () => import(/* webpackChunkName: "form-create" */ '@/components/pages/admin/polling-survey/form/create')
      },
      {
        path: 'polling-survey/form/:id/detail',
        name: 'FormDetail',
        props: true,
        component: () => import(/* webpackChunkName: "form-detail" */ '@/components/pages/admin/polling-survey/form/detail')
      },
      {
        path: 'polling-survey/form/:id/edit',
        name: 'FormEdit',
        props: true,
        component: () => import(/* webpackChunkName: "form-edit" */ '@/components/pages/admin/polling-survey/form/edit')
      },
      {
        path: 'polling-survey/response',
        name: 'Response',
        component: () => import(/* webpackChunkName: "response" */ '@/components/pages/admin/polling-survey/response')
      },
      {
        path: 'polling-survey/response/:id/detail',
        name: 'ResponseDetail',
        props: true,
        component: () => import(/* webpackChunkName: "response-detail" */ '@/components/pages/admin/polling-survey/response/detail')
      },
      {
        path: 'consumer/consumer',
        name: 'Consumer',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/consumer/consumer')
      },
      {
        path: 'consumer/consumer/:id/detail',
        name: 'ConsumerDetail',
        props: true,
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/consumer/consumer/edit-detail')
      },
      {
        path: 'consumer/consumer/coupon-history/:id/detail',
        name: 'ConsumerCouponDetail',
        props: true,
        component: () => import(/* webpackChunkName: "consumer-coupon-history" */ '@/components/pages/admin/consumer/consumer/coupon-history/detail')
      },
      {
        path: 'consumer/consumer/:id/edit',
        name: 'ConsumerEdit',
        props: true,
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/consumer/consumer/edit-detail')
      },
      {
        path: 'consumer/help-center',
        name: 'HelpCenter',
        props: true,
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/consumer/help_center')
      },
      {
        path: 'consumer/help-center/:id/detail',
        name: 'HelpCenterDetail',
        props: true,
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/consumer/help_center/detail')
      },
      {
        path: 'consumer/help-center/:id/edit',
        name: 'HelpCenterEdit',
        props: true,
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/consumer/help_center/edit')
      },
      {
        path: 'consumer/faq',
        name: 'Faq',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/consumer/faq')
      },
      {
        path: 'consumer/faq/create',
        name: 'FaqCreate',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/consumer/faq/create')
      },
      {
        path: 'consumer/faq/:id/detail',
        name: 'FaqDetail',
        props: true,
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/consumer/faq/detail')
      },
      {
        path: 'consumer/faq/:id/edit',
        name: 'FaqEdit',
        props: true,
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/consumer/faq/edit')
      },
      {
        path: 'consumer/template-help-center',
        name: 'TemplateHC',
        component: () => import(/* webpackChunkName: "templateHC" */ '@/components/pages/admin/consumer/template_help_center')
      },
      {
        path: 'consumer/template-help-center/create',
        name: 'TemplateHCCreate',
        component: () => import(/* webpackChunkName: "templateHC" */ '@/components/pages/admin/consumer/template_help_center/create')
      },
      {
        path: 'consumer/template-help-center/:id/detail',
        name: 'TemplateHCDetail',
        props: true,
        component: () => import(/* webpackChunkName: "templateHC" */ '@/components/pages/admin/consumer/template_help_center/detail')
      },
      {
        path: 'consumer/template-help-center/:id/edit',
        name: 'TemplateHCEdit',
        props: true,
        component: () => import(/* webpackChunkName: "templateHC" */ '@/components/pages/admin/consumer/template_help_center/edit')
      },
      {
        path: 'consumer/template-help-center/:id/duplicate',
        name: 'TemplateHCDuplicate',
        props: true,
        component: () => import(/* webpackChunkName: "templateHC" */ '@/components/pages/admin/consumer/template_help_center/duplicate')
      },
      {
        path: 'master-data/custom-consumer-tag',
        name: 'CustomTag',
        component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/admin/master-data/consumer-tag')
      },
      {
        path: 'master-data/custom-consumer-tag/create',
        name: 'CustomTagCreate',
        component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/admin/master-data/consumer-tag/create')
      },
      {
        path: 'master-data/custom-consumer-tag/:id/detail',
        name: 'CustomTagDetail',
        props: true,
        component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/admin/master-data/consumer-tag/detail')
      },
      {
        path: 'master-data/custom-consumer-tag/:id/edit',
        name: 'CustomTagEdit',
        props: true,
        component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/admin/master-data/consumer-tag/edit')
      },
      {
        path: 'master-data/group-store',
        name: 'Groupstore',
        component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/admin/master-data/group-store')
      },
      {
        path: 'master-data/group-store/create',
        name: 'GroupstoreCreate',
        component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/admin/master-data/group-store/create')
      },
      {
        path: 'master-data/group-store/:id/detail',
        name: 'GroupstoreDetail',
        props: true,
        component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/admin/master-data/group-store/detail')
      },
      {
        path: 'master-data/group-store/:id/edit',
        name: 'GroupstoreEdit',
        props: true,
        component: () => import(/* webpackChunkName: "group store" */ '@/components/pages/admin/master-data/group-store/edit')
      },
      {
        path: 'master-data/store',
        name: 'Store',
        component: () => import(/* webpackChunkName: "store" */ '@/components/pages/admin/master-data/store')
      },
      {
        path: 'master-data/product',
        name: 'Product',
        component: () => import(/* webpackChunkName: "store" */ '@/components/pages/admin/master-data/product')
      },
      {
        path: 'master-data/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import(/* webpackChunkName: "store" */ '@/components/pages/admin/master-data/privacy-policy')
      },
      {
        path: 'master-data/privacy-policy/edit',
        name: 'PrivacyPolicyEdit',
        component: () => import(/* webpackChunkName: "store" */ '@/components/pages/admin/master-data/privacy-policy/edit')
      },
      {
        path: 'master-data/setting-app',
        name: 'SettingApp',
        component: () => import(/* webpackChunkName: "setting-app" */ '@/components/pages/admin/master-data/setting-app')
      },
      {
        path: 'master-data/setting-app/edit',
        name: 'SettingAppEdit',
        component: () => import(/* webpackChunkName: "setting-app-edit" */ '@/components/pages/admin/master-data/setting-app/edit')
      },
      {
        path: 'master-data/term-condition',
        name: 'TermCondition',
        component: () => import(/* webpackChunkName: "store" */ '@/components/pages/admin/master-data/term-condition')
      },
      {
        path: 'master-data/term-condition/edit',
        name: 'TermConditionEdit',
        component: () => import(/* webpackChunkName: "store" */ '@/components/pages/admin/master-data/term-condition/edit')
      },
      {
        path: 'user-management/log-history',
        name: 'LogHistory',
        props: true,
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/user-management/log-history')
      },
      {
        path: 'user-management/log-history/:id/detail',
        name: 'LogHistoryDetail',
        props: true,
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/user-management/log-history/detail')
      },
      {
        path: 'user-management/user',
        name: 'User',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/user-management/user')
      },
      {
        path: 'user-management/user/create',
        name: 'UserCreate',
        component: () => import(/* webpackChunkName: "offers" */ '@/components/pages/admin/user-management/user/create')
      },
      {
        path: 'user-management/user/:id/detail',
        name: 'UserDetail',
        props: true,
        component: () => import(/* webpackChunkName: "offers" */ '@/components/pages/admin/user-management/user/detail')
      },
      {
        path: 'user-management/user/:id/edit',
        name: 'UserEdit',
        props: true,
        component: () => import(/* webpackChunkName: "offers" */ '@/components/pages/admin/user-management/user/edit')
      },
      {
        path: 'user-management/role',
        name: 'Role',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/user-management/role')
      },
      {
        path: 'user-management/role/create',
        name: 'RoleCreate',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/user-management/role/create')
      },
      {
        path: 'user-management/role/:id/detail',
        name: 'RoleDetail',
        props: true,
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/user-management/role/detail')
      },
      {
        path: 'user-management/role/:id/edit',
        name: 'RoleEdit',
        props: true,
        component: () => import(/* webpackChunkName: "offers" */ '@/components/pages/admin/user-management/role/edit')
      },
      {
        path: 'settings/profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/setting/profile')
      },
      {
        path: 'settings/change-password',
        name: 'ChangePassword',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/setting/change-password')
      },
      {
        path: 'role/inactive',
        name: 'RoleInactive',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/Inactive')
      },
      
      {
        path: 'unauthorized',
        name: 'Unauthorized',
        component: () => import(/* webpackChunkName: "unauthorized" */ '@/components/pages/Unauthorized')
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '@/components/pages/NotFound'),
    meta: {
      public: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(''),
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = isLogin()
  // let userRole;

  // if(loggedIn){
  //   userRole = getUserRole()
  // }

  // to.matched.map(record => {
  //   if (record.meta.roles === undefined) {
  //     next()
  //   }else{
  //     if(record.meta.roles.some(item => item == userRole)){
  //       next()
  //     }else{
  //       next("/admin/unauthorized")
  //     }
  //   }
  // })

  if (!isPublic && !loggedIn) {
    if (to.fullPath == '/admin/role/inactive') {
      return next({
        path:'/login'
      })
    }
    return next({
      path:'/login',
      query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
    })
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/admin')
  }

  next()
})

export default router
