import { apiPostAuth, apiPutAuth, apiGetAuth, apiDeleteAuth } from '@/utils/api'
import { API_CONSUMER, API_MASTER_DATA, API_USER_MANAGEMENT } from '@/utils/api-url'
import router from '@/router'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    detail_user: { data: []},
    list_all_consumer: { data: [] },
    list_consumer_by_custom_tag: { data: [] },
    list_bulk_upload: { data: [] },
    custom_tag: { data: [] },
    detail_custom_tag: { data: [] },
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_DETAIL_USER (state, payload) {
    state.detail_user = payload
  },
  SET_LIST_ALL_CONSUMER(state, payload) {
    state.list_all_consumer = payload
  },
  SET_LIST_CONSUMER_BY_CUSTOM_TAG(state, payload) {
    state.list_consumer_by_custom_tag = payload
  },
  SET_BULK_UPLOAD (state, payload) {
    state.list_bulk_upload = payload
  },
  SET_CUSTOM_TAG (state, payload) {
    state.custom_tag = payload
  },
  SET_DETAIL_CUSTOM_TAG (state, payload) {
    state.detail_custom_tag = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListConsumer (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.LIST_CONSUMER, payload)
      return response.data.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListAllConsumer (context) {
    try {
      const response = await apiGetAuth(API_CONSUMER.LIST_CONSUMER, {is_all: true})
      context.commit('SET_LIST_ALL_CONSUMER', response.data.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListConsumerByCustomTag (context, payload) {
    try {
      const response = await apiPostAuth(API_MASTER_DATA.LIST_CONSUMER_BY_DETAIL_CUSTOM_TAG + `${Object.keys(payload.reqParam).length > 0 ? `?search=${payload.reqParam?.search || ''}&status=${payload.reqParam?.status || ''}` : ''}`, payload.reqBody)
      context.commit('SET_LIST_CONSUMER_BY_CUSTOM_TAG', response.data.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListAllDetailUser (context, payload) {
    try {
      const response = await apiPostAuth(API_USER_MANAGEMENT.ALL_DETAIL_USER, payload)
      context.commit('SET_DETAIL_USER', response.data.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListCustomConsumerTag (context, payload) {
    try {
      const response = await apiGetAuth(API_MASTER_DATA.CONSUMER_TAG, payload)
      context.commit('SET_CUSTOM_TAG', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({ name: 'CustomTag', query: payload })
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getDetailCustomConsumerTag (context, payload) {
    try {
      const response = await apiGetAuth(API_MASTER_DATA.DETAIL_CUSTOM_CONSUMER_TAG(payload))
      context.commit('SET_DETAIL_CUSTOM_TAG', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async bulkUploadCustomConsumerTag (context, payload) {
    try {
      const response = await apiPostAuth(API_MASTER_DATA.BULK_UPLOAD_CONSUMER_TAG, payload)
      context.commit('SET_BULK_UPLOAD', response.data.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(error)
    }
  },
  async createCustomConsumerTag (context, payload) {
    try {
      const response = await apiPostAuth(API_MASTER_DATA.CONSUMER_TAG, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(error)
    }
  },
  async updateCustomConsumerTag (context, payload) {
    try {
      const response = await apiPutAuth(API_MASTER_DATA.UPDATE_CUSTOM_CONSUMER_TAG(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async deleteCustomConsumerTag (context, payload) {
    try {
      const response = await apiDeleteAuth(API_MASTER_DATA.DELETE_CUSTOM_CONSUMER_TAG(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  detail_user (state) {
    return state.detail_user
  },
  list_all_consumer (state) {
    return state.list_all_consumer
  },
  list_consumer_by_custom_tag (state) {
    return state.list_consumer_by_custom_tag
  },
  list_bulk_upload (state) {
    return state.list_bulk_upload
  },
  list_custom_consumer_tag (state) {
    return state.custom_tag
  },
  detail_custom_consumer_tag (state) {
    return state.detail_custom_tag
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
